import React from "react"
import TopMenu from "../components/top-menu"
import FooterMenu from "../components/footer-menu"
import SideBar from "../components/sidebar"
import NavBar from "../components/navbar"
import Footer from "../components/footer"
import useResponsive from '../utils/useResponsive'



export default function Layout({ children, data }) {
  return (
    <>
      {useResponsive().isDesktop && (
        <NavBar data={data} />
      )}
      {useResponsive().isMobile && (
        <TopMenu data={data} />
      )}
      {children}
      {useResponsive().isDesktop && (
        <Footer data={data} />
      )}
      {useResponsive().isMobile && (
        <div className="pb-20">
          <Footer data={data} />
          <FooterMenu data={data} />
        </div>
      )}
    </>
  )
}