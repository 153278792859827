import React, { useState } from "react"
import { FaPhone, FaLocationArrow, FaInstagram, FaYelp, FaTwitter, FaFacebook, FaTripadvisor, FaFoursquare, FaTimes } from "react-icons/fa";

export default function SocialLogo({ data, color }) {
  const logoColor = color === 'bright' ? 'text-gray-50' : 'text-gray-900'
  return (
    <div className="flex flex-row items-center justify-center space-x-6">
      {data.restaurant.websiteSettings?.linkInstagram &&
        <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer" className={logoColor}>
          <FaInstagram size="2.5em" />
        </a>
      }
      {data.restaurant.websiteSettings?.linkTwitter &&

        <a href={`${data.restaurant.websiteSettings?.linkTwitter}`} target="_blank" rel="noreferrer" className={logoColor}>
          <FaTwitter size="2.5em" />
        </a>
      }
      {data.restaurant.websiteSettings?.linkFacebook &&

        <a href={`${data.restaurant.websiteSettings?.linkFacebook}`} target="_blank" rel="noreferrer" className={logoColor}>
          <FaFacebook size="2.5em" />
        </a>
      }
      {data.restaurant.websiteSettings?.linkTripadvisor &&

        <a href={`${data.restaurant.websiteSettings?.linkTripadvisor}`} target="_blank" rel="noreferrer" className={logoColor}>
          <FaTripadvisor size="2.5em" />
        </a>
      }
      {data.restaurant.websiteSettings?.linkFoursquare &&

        <a href={`${data.restaurant.websiteSettings?.linkFoursquare}`} target="_blank" rel="noreferrer" className={logoColor}>
          <FaFoursquare size="2.5em" />
        </a>
      }
      {data.restaurant.websiteSettings?.linkYelp &&

        <a href={`${data.restaurant.websiteSettings?.linkYelp}`} target="_blank" rel="noreferrer" className={logoColor}>
          <FaYelp size="2.5em" />
        </a>
      }

    </div>
  )
}