import React from "react"

export default function DynamicColor({ data }) {
  return (
    <>
      {
        data.restaurant?.websiteSettings?.color && (
          <style> {`body {
                      --maintext-color: ${data.restaurant.websiteSettings.color ?? '#000000'};
                      --secondtext-color: ${data.restaurant.websiteSettings.colorSecond ?? '#000000'};
                      --background-color: ${data.restaurant.websiteSettings.colorBackground ?? '#FFFFFF'};
                    }`}
          </style>
        )
      }
    </>
  )
}