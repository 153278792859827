import React, { useState } from "react"
import { SiUbereats, SiDeliveroo } from "react-icons/si";
import { RiTakeawayLine } from "react-icons/ri"
import { MdShoppingBasket } from "react-icons/md"
import Modal from 'react-modal';
import Shortcut from "../components/shortcut"
import SocialLogo from "../components/social-logo"
import useResponsive from '../utils/useResponsive'

export default function Footer({ data }) {
  return (
    <footer className="footer max-w-xlmx-auto">
      {useResponsive().isDesktop && (
        <div className="flex flex-col items-center justify-center max-w-auto h-40 py-20">
          <h3 className="py-4 text-gray-900 text-2xl font-extrabold">Nos liens rapides</h3>
          <div className="flex flex-row items-center justify-center">
            <Shortcut data={data} size="small" />
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center max-w-auto h-24 py-20">
        <h3 className="py-4 text-gray-900  text-2xl font-extrabold">Rejoignez nous sur</h3>
        <SocialLogo data={data} />
      </div>

      {useResponsive().isDesktop && (
        <div className="flex flex-row items-center justify-center px-10 pt-5 h-50">
          <div className="text-gray-900">
            {data.restaurant.informations.name}
          </div>
          <div className="text-gray-900 pl-2">
            {data.restaurant.informations.address}, {data.restaurant.informations.postalCode} {data.restaurant.informations.city}, France
        </div>
          <div className="pl-2">
            <a className="text-gray-900 border-2">
            {data.restaurant.informations.phone}
          </a>
          </div>
        </div>
      )}

      <div className="flex flex-row pb-10 h-20 mx-3 justify-center items-center text-center">
        <div className="text-gray-900">
          {`© ${new Date().getFullYear()} ${data.restaurant.informations.name}`} | Mentions légales | Realisée par <a href="https://we-eats.com" target="_blank" rel="noreferrer">WE EATS</a>
        </div>
      </div>
    </footer >
  )
}
