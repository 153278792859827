import React, { useState } from "react"
import { Link } from "gatsby"
import Modal from 'react-modal';

import { FaPhone, FaLocationArrow, FaInstagram, FaYelp, FaTwitter, FaFacebook, FaTripadvisor, FaFoursquare, FaTimes } from "react-icons/fa";

export default function NavBar({ data }) {
  const [iframe, showIframe] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
      zIndex: 10
    }
  };

  return (
    <div className="grid grid-cols-3 shadow-md navbar">
      <div className="buisness">
        {data.restaurant.websiteSettings?.logo && (
          <Link to="/">
            <img src={`${data.restaurant.websiteSettings?.logo[0].path}`} alt="logo" />
          </Link>
        )}
      </div>


      <div className="links flex flex-row items-center justify-center">
        <nav>
          <Link to="/">ACCUEIL</Link> •
          <Link to="/menu/">CARTE</Link> •
          <Link to="/avis/">AVIS</Link>
        </nav>
      </div>

      <div className="actions">
        {data.restaurant.urlName && data.restaurant.websiteSettings?.reservation && (
          <a href={'##'} onClick={() => showIframe(!iframe)} data-item="Réservation">
            <div className="action-button">Réservation</div>
          </a>
        )}

        {data.restaurant.settings?.takeaway && (
          <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer">
            <div className="action-button">Commander</div>
          </a>
        )}

      </div>
      <Modal
        isOpen={iframe}
        onRequestClose={() => showIframe(!iframe)}
        style={customStyles}
        contentLabel="Reservation"
      >
        {data.restaurant.urlName !== 'nagoya' && (
          <iframe src={`https://reservation.we-eats.com/${data.restaurant.urlName}`} width="500" height="800" frameBorder="0" title="réservation" />
        )}
        {data.restaurant.urlName == 'nagoya' && (
          <iframe src="https://booking.ureserve.co/shop/external-booking/nagoya?iframe=true" width="500" height="700" frameBorder="0" title="réservation"></iframe>
        )}

        <span className="modal-close-btn" onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} role="button" tabIndex={0}>
          <FaTimes color="white" size="1.8em" />
        </span>

      </Modal>
    </div >
  )
}

