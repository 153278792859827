import React, { useState } from "react"
import { Link } from "gatsby"
import { HiMenu, HiOutlineX } from "react-icons/hi";
import SocialLogo from "../components/social-logo"

import '../styles/top-menu.scss'


export default function TopMenu({ children, data }) {
  const [open, setOpen] = useState(false)

  return (
    <div className="top-menu">
      {data.restaurant.websiteSettings?.logo && (
        <img src={`${data.restaurant.websiteSettings?.logo[0].path}`} alt="logo" />
      )}

      {!data.restaurant.websiteSettings?.logo && (
        <div />
      )}

      <div onClick={() => setOpen(true)} role="button"><HiMenu size="2em" /></div>

      <nav className={`menu ${open ? `open` : ""}`} id="main-nav">
        <div onClick={() => setOpen(false)} role="button" className="absolute top-6 right-4">
          <HiOutlineX size="2.4em" />
        </div>

        <Link to="/" className="text-2xl">ACCUEIL</Link>
        <Link to="/menu/" className="text-2xl">MENU</Link>
        <Link to="/avis/" className="text-2xl">AVIS</Link>

        <SocialLogo data={data}/>

        <div className="footer">
          <p>{`© ${new Date().getFullYear()} ${data.restaurant.informations.name}`}</p>
          <p>
            Site realisé par <a href="https://we-eats.com" target="_blank" rel="noreferrer">WE EATS</a>
          </p>
        </div>
      </nav>

    </div>
  )
}
